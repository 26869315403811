@charset "utf-8";

html {
    box-sizing: border-box;
    background-color: rgb(42, 88, 121);
}
*, *:before, *:after {
    box-sizing: inherit;
}

h1 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
    color: bisque;
}

